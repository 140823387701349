import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


function Navegador(){

    let web = window.location.origin;
    let consulta = window.location.search;


    let url = web+"?dapp"
    let text = "Launch App";

    if (consulta.indexOf("dapp") >= 0){
        url = web+"/"
        text = "Home Page";
    }

    return (<>
    <div id="sticky-header" className="cryptobit_nav_manu">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-4">
                    <div className="logo">
                        <a className="logo_img" href="/" title="ciroTRX">
                            <img src="assets/images/cirotrxlogo.png" alt="" />
                        </a>
                        <a className="main_sticky" href="/" title="ciroTRX">
                            <img src="assets/images/cirotrxlogo.png" alt="astute" />
                        </a>
                    </div>
                </div>
                <div className="col-lg-8">
                    <nav className="cryptobit_menu">
                        <ul className="nav_scroll">
                            <li><a className="tiemMenu" href={web+"#home"}>Home</a></li>
                            <li><a className="tiemMenu" href={web+"#mining"}>Mining</a></li>
                            <li><a className="tiemMenu" href={web+"#staking"}>Staking</a></li>
                            <li><a className="tiemMenu" href={web+"#nft"}>NFT</a></li>
                            <li><a className="tiemMenu" href={web+"#contact"}>Contact</a></li>

                        </ul>
                        <div className="header-button">
                            <a className="tiemMenu conectDapp" href={url}>{text}</a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>

    <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
    <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="bg-body-tertiary ">
      <Container>
        <Navbar.Brand href={url}><img src="assets/images/cirotrxlogo.png" alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor: "gray"}} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="text-light " href={web+"#home"}>Home</Nav.Link>
            <Nav.Link className="text-light " href={web+"#mining"}>Mining</Nav.Link>
            <Nav.Link className="text-light " href={web+"#staking"}>Staking</Nav.Link>
            <Nav.Link className="text-light " href={web+"#contact"}>Contact</Nav.Link>
            <Nav.Link className="text-light " href={web+"#nft"}>NFT</Nav.Link>
            <Nav.Link className="text-light " href={url}>{text}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>

    </>)
}

export default Navegador;