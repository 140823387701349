import React, { Component } from "react";

export default class Inicio extends Component {

	render() {

		return (
			<>
				<div className="clearfix" style={{ clear: "both" }}></div>
				<div id="home" className="hero-section">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="hero-content">
									<div className="hero-title">
										<h1 className="hero-title">Stablecoins</h1>
										<h1 className="hero">made easy</h1>
									</div>
									<div className="hero-text">
										<p>Cirotrx allows users to send stablecoins on the Tron blockchain <br />without requiring TRX or energy in their wallet,<br />providing more accessible and straightforward transactions.</p>
									</div>
									<div className="hero-button">
										<a href="?dapp">Send Stables</a>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="dreamit-hero-thumb">
									<div className="hero-main-thumb">
										<img src="assets/images/slider/tronlink.png" alt="" />
									</div>
									<div className="hero-thumb-inner1 bounce-animate4">
										<img src="assets/images/slider/usdttranslucido.png" alt="" />
									</div>
									<div className="hero-thumb-inner2 bounce-animate">
										<img src="assets/images/slider/usdctranslucido.png" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="clearfix" style={{ clear: "both" }}></div>

				<div id="mining" className="about-area pt-100 pb-100">
					<div className="container">
						<div className="row">

							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="dreamit-about-thumb">
									<img src="assets/images/mining-ciro.png" alt="mining token ciro cirotrx" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="dreamit-section-title pb-5" style={{ textAlign: "justify" }}>
									<h1>Join The <b>CIRO Mining</b></h1>
									<p className="section-text">Mining evolves with CiroMining, a revolutionary way to earn rewards by simply delegating your energy to our main contract. Every time you allocate your energy, you contribute to the CiroTRX ecosystem and receive a proportional share of Ciro tokens as rewards.</p>

									<ul className="lista">
										<li><b>Accessible:</b> Compatible with all Tron wallets.</li>
										<li><b>No Hidden Costs:</b> Your energy, your reward.</li>
										<li><b>Daily Rewards:</b> Receive your rewards every day.</li>
										<li><b>Transparent:</b> Track your rewards in real-time.</li>
										<li><b>Secure:</b> Your TRX always stays in your wallet.</li>
										<li><b>No Permissions:</b> No need to grant additional wallet permissions.</li>

									</ul>

								</div>
								<div className="hero-button">
									<a href="?dapp">Start Mining</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="staking" className="team-area pt-100 pb-100">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="dreamit-section-title pb-5" style={{ textAlign: "justify" }}>
									<h1>Earn with <b>CIRO Staking </b></h1>
									<p className="section-text">Take your participation in the Ciro ecosystem to the next level by staking your Ciro tokens. With CiroStaking, you can earn rewards in stablecoins (USDT) collected from transaction fees across the platform.</p>
									<p>
										Stake your tokens, accumulate rewards, and claim them directly through the dApp. The more you stake, the larger your share of the daily reward pool.<br />
									</p>
									<ul className="lista">
										<li><b>Rewards:</b> Earn USDT rewards.</li>
										<li><b>Transparent:</b> Track your rewards and staking performance.</li>
										<li><b>Flexible Staking:</b> Stake or unstake your tokens anytime.</li>
										<li><b>Earnings:</b> Claim your accumulated rewards whenever you like.</li>
										<li><b>Boost Your Profits:</b> Higher amount, the greater your share of the rewards.</li>
									</ul>
								</div>
								<div className="hero-button">
									<a href="?dapp">Start Staking</a>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12" >
								<div className="dreamit-about-thumb" >
									<img src="assets/images/ciro-staking.png" alt="ciro staking" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="stable" className="about-area pt-100 pb-100">
					<div className="container">
						<div className="row">

							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="dreamit-about-thumb">
									<img src="assets/images/resource/ciroesquema.png" alt="cirotrx" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="dreamit-section-title pb-5">
									<h1>We use <b>most used Stablecoins</b> on <a href="https://tron.network/" target="_blank" rel="noopener noreferrer" style={{ color: 'red' }}>TRON Network</a></h1>
									<p className="section-text">we have tried to include in our system all the most known and used stable currencies.</p>

									<p>our team listens to community requests to add new stables and make the tron network more efficient. </p>
								</div>
								<div className="hero-button">
									<a href="#contact">Contact</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="nft" className="team-area pt-100 pb-55">
					<div className="container">
						<div className="row">
							<div className="dreamit-section-title text-center up pb-30">
								<h4>NFT</h4>
								<h1><span>Useless</span> Fee</h1>
								<p className="section-text">receive discounts on each of your shipments</p>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<div className="team-single-box">
									<div className="team-box-inner">
										<div className="team-thumb">
											<img src="assets/images/resource/about-1.png" alt="about cirotrx" height="90%" width="90%" />
										</div>
										<div className="team-content">
											<div className="team-title">
												<h3>Fee discount Silver</h3>
											</div>
											<div className="team-text">
												<p>10%</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-4">
								<div className="team-single-box upper1">
									<div className="team-box-inner">
										<div className="team-thumb">
											<img src="assets/images/resource/about-2.png" alt="about cirotrx" height="90%" width="90%" />
										</div>
										<div className="team-content">
											<div className="team-title">
												<h3>Fee discount Gold</h3>
											</div>
											<div className="team-text">
												<p>45%</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-4">
								<div className="team-single-box upper2">
									<div className="team-box-inner">
										<div className="team-thumb">
											<img src="assets/images/resource/about-3.png" alt="about cirotrx" height="90%" width="90%" />
										</div>
										<div className="team-content">
											<div className="team-title">
												<h3>Fee discount Diamond</h3>
											</div>
											<div className="team-text">
												<p>50%</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="contact" className="form-area pt-4 pb-50">
					<div className="container">
						<div className="row">
							<div className="dreamit-section-title text-center up pb-40">

								<h1><span>Contact</span> With Us</h1>
								<p className="section-text">At Cirotrx, powered by Brutus.Finance, your experience matters to us. Whether you have inquiries, feedback, or encounter any issues, our dedicated team is here to assist you. Navigating through the world of decentralized finance should be smooth, and we're here to ensure that your journey with us is seamless.</p>
							</div>
						</div>
						<div className="row text-center ">
							<div className="col-6">

								<div className="contact-icon-box">
									<a target="_blank" rel="noopener noreferrer" href="mailto:admin@cirotrx.com">
										<div className="contact-icon-thumb">
											<img src="assets/images/resource/contact1.png" alt="" />
										</div>
										<div className="contact-text">
											<p>admin@cirotrx.com</p>
										</div>
									</a>

								</div>
							</div>

							<div className="col-6">
								<div className="contact-icon-box">
									<a target="_blank" rel="noopener noreferrer" href="https://t.me/+M02lMfuL6mBlZWI0">
										<div className="contact-icon-thumb">
											<img src="assets/images/resource/contact3.png" alt="" />
										</div>
										<div className="contact-text">
											<p>Join us on Telegram</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>


			</>
		);
	}
}
